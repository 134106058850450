/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mes rendez-vous';
export const txtnombredoc = 'rendez-vous';
export const txtnombredocpluriel = 'rendez-vous';
export const titleAlveole = 'Mes rendez-vous';



// Local DeV
// export const zUrlBack = "https://localhost:8000"
// export const zAPIRendezvous = "https://localhost:8000/rendezvous";
// export const zUrlBackNotifications = "https://localhost:8001";

export const zAPIRendezvous = process.env.REACT_APP_API_RENDEZ_VOUS_SPE;
export const zUrlBack= process.env.REACT_APP_API_RENDEZ_VOUS;
export const zUrlBackNotifications = process.env.REACT_APP_API_SERVICENOTIFICATION;

export const zUrlAuthentication = process.env.REACT_APP_API_AUTHENTICATION

export const TypeRendezvous =[
    { id: 1, title: 'Analyse' },
    { id: 2, title: 'Visite' },
    { id: 3, title: 'Réunion' },
    { id: 4, title: 'Rendez-vous' }
];
// export const Typedocid = [1,2,3,4];
export const PasseAvenir =[
    { id: 5, title: 'Rendez-vous à venir' },
    { id: 6, title: 'Rendez-vous passés' }
];

/**
 * Fin constantes spécifiques alvéole
 */


/************************ Constantes ***********************************************/
export const zURLhome = process.env.REACT_APP_HOME_CUSTOMER;
export const zURLprofile = process.env.REACT_APP_PROFILE;
